




















import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import clienteModule from "@/store/modules/cliente-module";
import { CreateclienteDto } from "@/shared/dtos/CreateclienteDto";
import { API } from "@/shared/api";
import { Usuario } from "@/shared/dtos/usuario";

@Component({
  components: {
    DocumentsHandler: () => import("../Documentos/DocumentsHandler.vue"),
  },
})
export default class clienteFormulario extends Mixins(HelperDinamicallyForm) {
  public item_pantalla: any = new CreateclienteDto();

  public async OnCreated() {
    this.ShowLoading();

    if (this.getparamId().HayParametro) {
      await clienteModule.getcliente(this.getparamId().Parametro);
      this.item_pantalla = clienteModule.cliente;
    } else {
      this.item_pantalla.licencia_inicio = new Date();
      this.item_pantalla.licencia_fin = new Date();
      this.item_pantalla.usuario = new Usuario();
    }

    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    this.HideLoading();
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 10, 4);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.correo,
      "Correo",
      "correo",
      50
    )
      .Dimesiones(12, 10, 4)
      .isRequired()
      .SetValidacion("email");

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.telefono,
      "Teléfono",
      "telefono"
    )
      .Dimesiones(12, 10, 4)
      .isRequired();

    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.licencia_inicio),
      "Licencia inicio",
      "licencia_inicio"
    )
      .Dimesiones(12, 10, 4)
      .isRequired();
    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.licencia_fin),
      "Licencia fin",
      "licencia_fin"
    )
      .Dimesiones(12, 10, 4)
      .isRequired();

    this.AddFormItemDataDefaultString(
      this.item_pantalla.usuario.nombre,
      "Nombre usuario",
      "nombre_usuario",
      60
    )
      .isRequired(this.EstoyCreado)
      .Dimesiones(12, 10, 4)
      .isVisible(this.EstoyCreado);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.usuario.contra,
      "Contraseña usuario",
      "contra_usuario",
      60
    )
      .isRequired(this.EstoyCreado)
      .Dimesiones(12, 10, 4)
      .isVisible(this.EstoyCreado);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.usuario.nombre,
      "Email usuario",
      "email_usuario"
    )
      .isRequired(this.EstoyCreado)
      .Dimesiones(12, 10, 4)
      .isVisible(this.EstoyCreado)
      .SetValidacion("email");

    this.AddFormItemDataDefaultString(
      this.item_pantalla.datos_adicionales,
      "Datos adicionales",
      "datos_adicionales",
      50
    )
      .Dimesiones(12, 12, 12)
      .CreateTextArea();
  }

  public Insertar(object: any) {
    this.ShowLoading();
    object.usuario = new Usuario();
    object.usuario.nombre = object.nombre_usuario;
    object.usuario.contra = object.contra_usuario;
    object.usuario.email = object.email_usuario;
    object.usuario.id = 0;
    //emp1@gmail.com
    clienteModule.guardarcliente_all_data(object).then(() => {
      this.HideLoading();
      this.AllSaveOk();
    });
    this.HideLoading();
  }

  public Actualizar(object: CreateclienteDto) {
    clienteModule.modificarcliente(object).then(() => {
      this.AllSaveOk(); //guardo correctamente y me cierro
    });
  }
}
