import { store } from "@/store/store";
import { cliente } from "@/shared/dtos/cliente";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "clienteModule",
  store,
  dynamic: true,
})
class clienteModule extends VuexModule {
  public clientes: cliente[] = [];
  public cliente: cliente = new cliente();

  @Action({ commit: "onGetclientes" })
  public async getclientes() {
    return await ssmHttpService.get(API.cliente);
  }

  @Action({ commit: "onGetcliente" })
  public async getcliente(id: any) {
    return await ssmHttpService.get(API.cliente + "/" + id);
  }

  @Action
  public async guardarcliente(cliente: cliente) {
    return await ssmHttpService.post(API.cliente, cliente.toJson());
  }

  @Action
  public async guardarcliente_all_data(cliente: cliente) {
    return await ssmHttpService.post(
      API.cliente + "/cliente/",
      cliente.toJson()
    );
  }

  @Action
  public async modificarcliente(cliente: cliente) {
    return await ssmHttpService.put(API.cliente + "/" + cliente.id, cliente);
  }

  @Action
  public async eliminarcliente(cliente: cliente) {
    return await ssmHttpService.delete(
      API.cliente + "/" + cliente.id,
      null,
      false
    );
  }

  @Mutation
  public onGetclientes(res: cliente[]) {
    this.clientes = res ? res.map((x) => new cliente(x)) : [];
  }

  @Mutation
  public onGetcliente(res: cliente) {
    this.cliente = new cliente(res);
  }
}
export default getModule(clienteModule);
